var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-orders-container" }, [
    _c("div", { staticClass: "my-orders-content font-menu-small" }, [
      _c("div", { staticClass: "my-orders-item" }, [
        _c("div", { staticClass: "order-status" }, [
          _c("div", { staticClass: "order-date font-menu-medium" }, [
            _vm._v(" " + _vm._s(_vm.ordersInfo.createDate) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "id-and-status",
              class: { cancelled: _vm.ordersInfo.status === "CANCELLED" },
            },
            [
              _c("div", { staticClass: "id" }, [
                _vm._v("ID" + _vm._s(_vm.ordersInfo.id)),
              ]),
              _c("div", [_vm._v("|")]),
              _c("div", { staticClass: "status" }, [
                _vm._v(" " + _vm._s(_vm.ordersInfo.status) + " "),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "order-detail font-menu-small" }, [
          _c("div", { staticClass: "order-item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("COURSE")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                " " + _vm._s(_vm.ordersInfo.courseNum) + " Classes Package "
              ),
            ]),
          ]),
          _c("div", { staticClass: "order-item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("TOTAL PRICE")]),
            _vm.currency === 0
              ? _c("div", { staticClass: "value" }, [
                  _c("span", [_vm._v("￥ ")]),
                  _vm._v(_vm._s(_vm.ordersInfo.totalPrice) + " "),
                ])
              : _vm.currency === 1
              ? _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.ordersInfo.totalPrice)),
                  _c("span", [_vm._v(" €")]),
                ])
              : _c("div", { staticClass: "value" }, [
                  _c("span", [_vm._v("$ ")]),
                  _vm._v(_vm._s(_vm.ordersInfo.totalPrice) + " "),
                ]),
          ]),
          _c("div", { staticClass: "order-item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("UNIT PRICE")]),
            _vm.currency === 0
              ? _c("div", { staticClass: "value" }, [
                  _c("span", [_vm._v("￥ ")]),
                  _vm._v(_vm._s(_vm.ordersInfo.unitPrice) + " "),
                ])
              : _vm.currency === 1
              ? _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.ordersInfo.unitPrice)),
                  _c("span", [_vm._v(" €")]),
                ])
              : _c("div", { staticClass: "value" }, [
                  _c("span", [_vm._v("$ ")]),
                  _vm._v(_vm._s(_vm.ordersInfo.unitPrice) + " "),
                ]),
          ]),
          _c("div", { staticClass: "order-item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("START DATE")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.ordersInfo.startDate)),
            ]),
          ]),
          _c("div", { staticClass: "order-item" }, [
            _c("div", { staticClass: "name" }, [_vm._v("END DATE")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.ordersInfo.endDate)),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "order-item" }, [
      _c("div", { staticClass: "name" }, [_vm._v("INVOICE")]),
      _c("div", { staticClass: "value" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/19-Dashboard/icon-invoice.svg"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }