var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-orders-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                        on: {
                          click: function ($event) {
                            return _vm.changePage(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "currency-area" }, [
                    _c(
                      "div",
                      { staticClass: "currency font-menu-small" },
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "currency-item",
                            class: { active: _vm.currencyNum === index },
                            on: {
                              click: function ($event) {
                                return _vm.changeCurrency(index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.value) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "my-order-box" },
                    [
                      _vm._l(_vm.myOrdersList, function (item, index) {
                        return [
                          _c("Orders", {
                            key: index,
                            attrs: {
                              ordersInfo: item,
                              currency: _vm.currencyNum,
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }